.row2 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 15px;
}

.client-row2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.client-row2-col1 {
  width: 60%;
  display: flex;
  flex-flow: column;
  margin: 0 10px;
  padding-right: 10px;
}

.client-row2-col2 {
  width: 40%;
  display: flex;
  margin-top: -69px;
  flex-flow: row nowrap;
  padding-right: 10px;
}

.client-row {
  display: flex;
  flex-flow: row nowrap;
}

.client-zip-code {
  display: flex;
  width: 20%;
  margin-right: 5px;
  flex-direction: column;
}

.client-street {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.client-number {
  display: flex;
  width: 20%;
  margin: 10px 5px 0 0;
  flex-direction: column;
}

.client-address-details {
  display: flex;
  width: 20%;
  margin: 10px 5px 0 0;
  flex-direction: column;
}

.client-district {
  width: 60%;
  margin: 10px 0 0 0;
  flex-direction: column;
}

.client-city {
  display: flex;
  width: 90%;
  margin: 10px 5px 0 0;
  flex-direction: column;
}

.client-state {
  width: 10%;
  margin: 10px 0 0 0;
  flex-direction: column;
}

.client-phone {
  width: 35%;
  margin: 0 5px 0 0;
  flex-direction: column;
  min-width: 132px;
}

.client-phone-details {
  width: 65%;
  margin: 0 0 0 0;
  flex-direction: column;
}

.client-phone-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  margin-left: -24px;
  margin-top: 5px;
  padding-right: 10px;
  background: none;
  border: none;
}