#components-popover-demo-placement .ant-btn {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  padding: 0;
}

#components-popover-demo-placement .ant-btn {
  margin-left: 8px;
  margin-right: 0;
}

.undo-filter-date-filter {
  width: 130px;
  cursor: pointer;
}

.date-picker-input-date-filter {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.date-range-date-filter {
  width: 100%;
  border: 1px rgb(220, 220, 220) solid;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.date-input-date-filter {
  border: none;
  border-radius: 5px;
  padding: 8px;
  transition: border-color 0.5s ease;
  color: #696969;
  margin: 0 5px;
}

.date-input-date-filter:focus {
  color: rgba(0, 123, 255);

  outline: none;
}

.filter-buttons-date-filter {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}