.client-form-modal {
  display: flex;
}

.action-buttons {
  display: flex;
  flex: row nowrap;
  width: 320px;
  justify-content: flex-end;
  margin-right: 10px;
  padding-right: 10px;
  gap: 10px;
}

.save-button {
  width: 50px;
  font-size: 12px;
}