.yellow-button {
    height: 50px;
    width: 200px;
    border-radius: 45px;
    background: #EFC337;
    border: none;
    color: #171F48;
    font-weight: bold;
}

.yellow-button:hover {
    color: #FFF;
    cursor: pointer;
}