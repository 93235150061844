.red-button {
    height: 35px;
    width: 150px;
    border-radius: 5px;
    background: red;
    border: none;
    color: #FFF;
    font-weight: bold;
}

.red-button:hover {
    cursor: pointer;
}