.change-pw-input {
    margin: 10px 0;
}

.password-alert {
    display: flex;
    font-size: 12px;
    margin-bottom: 0;
    color: red;
    font-weight: 500;
}