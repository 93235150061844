.display-reports {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  background-blend-mode: darken;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-filter {
  height: 200px;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 1;
}

.background-header-fixed {
  height: 260px;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 6px 6px 6px 1px rgba(0, 0, 0, 0.3);
}

.menu-reports {
  display: flex;
  width: 100%;
  height: 40px;
  position: fixed;
  flex-flow: row nowrap;
  top: 110px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  align-content: center;
  z-index: 3;
}

.filter-reports {
  display: flex;
  width: 100%;
  height: 35px;
  position: fixed;
  flex-flow: row nowrap;
  top: 195px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 18px;
  align-content: center;
  z-index: 1;
}

.interface-reports {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 260px;
}

.select-report {
  width: 50%;
  min-width: 250px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.select-period {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.select-period-inputs {
  display: flex;
  align-content: center;
  align-items: center;
}

.select-period-type {
  width: 120px;
  margin-right: 10px;
}

.select-period-dates {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.select-period-date-label {
  font-size: 12px;
  margin-top: 5px;
}

.select-product {
  width: 50%;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.select-origin {
  width: 50%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.select-partner {
  width: 30%;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.generate-reports-btn {
  display: flex;
  margin-top: 28px;
}