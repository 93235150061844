.report1-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-weight: bold;
  border-top: 2px rgb(87, 87, 87) solid;
  border-bottom: 2px rgb(87, 87, 87) solid;
}

.report1-summary {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 80px;
  margin-top: -10px;
  border-bottom: 2px rgb(87, 87, 87) solid;
}

.report1-summary-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}