.dashboard-cases-count {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  align-content: center;
  gap: 5px;
}

.dashboard-cases-count-section {
  display: flex;
  gap: 1%;
}