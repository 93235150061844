.dashboard-cases-near-deadline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: 100%;
}

.dashboard-cases-near-deadline-section-1 {
  display: flex;
  width: 100%;
}

.dashboard-cases-near-deadline-section-2 {
  display: flex;
  width: 100%;
  gap: 0.5%;
}

.dashboard-cases-near-deadline-section-3 {
  display: flex;
  flex-direction: column;
  gap: 0.5%;
  overflow-y: scroll;
  width: 100%;
}

.dashboard-cases-near-deadline-section-3-row {
  display: flex;
  gap: 0.5%;
  margin-bottom: 2px;
}