.product-form-modal {
  display: flex;
}

.product-modal-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 6px;
  justify-content: space-between;
}

.product-label {
  display: flex;
  font-size: 12px;
  margin-bottom: 0;
  color: #171f48;
  font-weight: 700;
}

.product-input {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
}

.product-input-group {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.product-docs {
  font-size: 12px;
  margin-left: 6px;
}