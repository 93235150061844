.content-text-filter {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  width: auto;
  min-width: 300px;
}

.input-text-filter {
  margin-bottom: 10px;
}

.undo-filter-text-filter {
  margin-top: 10px;
}