.dashboard-section-1 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.dashboard-section-2 {
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 10%;
}

.dashboard-section-3 {
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
}

.dashboard-section-4 {
  display: flex;
  width: 100%;
}

.dashboard-object-1 {
  grid-area: 1 / 1 / span 1 / span 1;
  /* Linha inicial / coluna inicial / qtd. linhas / qtd. colunas */
  width: 100%;
  height: 236px;
}

.dashboard-object-2 {
  grid-area: 1 / 2 / span 1 / span 1;
  /* Linha inicial / coluna inicial / qtd. linhas / qtd. colunas */
  width: 100%;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: top;
  justify-items: center;
}

.dashboard-object-3 {
  grid-area: 1 / 3 / span 1 / span 1;
  /* Linha inicial / coluna inicial / qtd. linhas / qtd. colunas */
  width: 100%;
  height: 236px;
  border-bottom: 1px solid #171f48;
}

.dashboard-object-4 {
  grid-area: 2 / 1 / span 1 / span 2;
  /* Linha inicial / coluna inicial / qtd. linhas / qtd. colunas */
  width: 100%;
  height: calc(100vh - 396px);
}

.dashboard-object-5 {
  grid-area: 2 / 3 / span 1 / span 2;
  /* Linha inicial / coluna inicial / qtd. linhas / qtd. colunas */
  width: 100%;
  margin-top: 16px;
  height: calc(100vh - 412px);
  border-bottom: 1px solid #171f48;
}