.display-financial-transactions {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  justify-content: center;
  align-items: center;
  background-blend-mode: darken;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.menu-financial-transactions {
  display: flex;
  width: 100%;
  height: 40px;
  position: fixed;
  flex-flow: row nowrap;
  top: 110px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  align-content: center;
}

.filter {
  display: flex;
  width: 100%;
  height: 35px;
  position: fixed;
  flex-flow: row nowrap;
  top: 195px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 18px;
  align-content: center;
}

.interface-financial-transactions {
  display: flex;
  width: 100%;
  position: fixed;
  flex-flow: row nowrap;
  top: 240px;
  height: 100%;
}

.menu-financial-transactions-btn-container {
  display: flex;
  flex-flow: row;
  width: 1095px;
  justify-content: flex-start;
  margin-right: 30px;
  gap: 5px
}