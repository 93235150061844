#components-popover-demo-placement .ant-btn {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  padding: 0;
}

#components-popover-demo-placement .ant-btn {
  margin-left: 8px;
  margin-right: 0;
}

.content-radio-filter {
  display: flex;
  flex-direction: column;
}

.options-radio-filter {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  width: auto;
}

.undo-filter-radio-filter {
  margin-top: 10px;
}