.menu-icon {
  width: 35px;
  height: auto;
  display: flex;
  position: absolute;
  outline: none;
  z-index: 1;
}

.icon-button {
  outline: none;
}

.list {
  padding-top: 95px;
  width: 210px;
}

.header-list {
  position: absolute;
  left: 0;
}

.background-list,
.background-sub-list {
  color: #171f48;
  height: 36px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-list:hover {
  background-color: #EFC337;
  cursor: pointer;
  font-size: 20px;
}

.background-sub-list:hover {
  background-color: rgb(23, 31, 72, 0.1);
  cursor: pointer;
  font-size: 20px;
}

.background-list-active {
  color: #171f48;
  height: 36px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1dd98;
}

.background-list-active:hover {
  cursor: default;
}