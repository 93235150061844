.gray-button {
  height: 35px;
  width: 150px;
  border-radius: 5px;
  background: rgb(235, 235, 235);
  border: none;
  color: #171F48;
  font-weight: bold;
}

.gray-button:hover {
  color: #FFF;
  background: rgb(179, 179, 179);
  cursor: pointer;
}