.row3 {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 20px;
}

.partner-row3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.partner-row3-col1 {
  width: 60%;
  display: flex;
  flex-flow: column;
  margin: 0 0px 0 10px;
  padding-right: 20px;
}

.partner-row3-col2 {
  width: 40%;
  display: flex;
  flex-flow: column;
}

.partner-benefit-and-origin {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input-benefit-and-origin-group {
  width: 100%;
}

.next-row {
  margin-top: 10px;
}

.benefit-and-origin-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.partner-commission-stage {
  margin-top: 4px;
  width: 115px;
}

.partner-commission {
  width: 100%;
}

.partner-commission-calculation-basis {
  width: 100%;
  margin: 0 5px;
}

.partner-origin {
  width: 100%;
}

.partner-partners {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input-partners-group {
  width: 100%;
  margin: 10px 10px 0 0;
}

.partners-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.partner-logs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.partner-log-field {
  height: 209px;
  width: 100%;
  display: flex;
  border: 1px solid #818a91;
  border-radius: 3px;
  overflow-y: scroll;
}

.partner-admission {
  width: 20%;
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column
}

.partner-registration {
  width: 20%;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column
}

.partner-admission-group {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.partner-bank {
  width: 30%;
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column
}

.partner-bank-code {
  width: 10%;
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column
}

.partner-bank-pix {
  width: 60%;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column
}

.partner-bank-branch,
.partner-bank-account {
  width: 30%;
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column
}

.card-log {
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: column;
  border-bottom: 1px gray solid;
}

.log-input {
  display: flex;
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 13px;
}

.log-row {
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  padding: 2px;
  font-size: 12px;
  background-color: rgb(240, 240, 240);
}

.partner-label {
  display: flex;
  font-size: 12px;
  margin-bottom: 0;
  color: #171f48;
  font-weight: 700;
}