.transaction-dialog-actions {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: right;
}

.row1 {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin-bottom: 0px;
}

.transaction-row1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-flow: row nowrap;
}

.transaction-row1-col1 {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.transaction-date {
    display: flex;
    width: 19%;
    flex-direction: column;
    margin: 0 5px 0 0;
}

.transaction-value {
    display: flex;
    width: 34%;
    flex-direction: column;
    margin: 0 5px 0 0;
}

.transaction-category {
    display: flex;
    width: 27%;
    flex-direction: column;
    margin: 0 5px 0 0;
}

.transaction-type {
    display: flex;
    width: 19%;
    flex-direction: column;
    margin: 0 0px 0 0;
}

.transaction-origin {
    display: flex;
    width: 19%;
    flex-direction: column;
    margin: 0 5px 0 0;
}

.transaction-partner {
    display: flex;
    width: 15%;
    flex-direction: column;
    margin: 0 5px 0 0;
}

.transaction-datails {
    display: flex;
    width: 47%;
    flex-direction: column;
}

.transaction-date-final {
    display: flex;
    width: 19%;
    flex-direction: column;
    text-align: center;
}