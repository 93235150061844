@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.input {
  color: #373A3C;
  border: 1px solid #818A91;
  height: 35px;
  border-radius: 3px;
  padding: 8px;
  width: 100%;
  max-width: 100%;
  font-size: 13px;
}

.label {
  font-weight: bold;
  font-size: 14px;
}

.error {
  color: red;
  font-size: 12px;
  height: 15px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  height: 42px;
  width: 100%;
  padding: 10px 15px 0 0;
}

.table-body {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  height: 30px;
  background: white;
  border-bottom: 1px rgb(190, 190, 190) solid;
  font-size: 16px;

}

.table-body-fixed {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  height: 30px;
  background: white;
  border-bottom: 1px rgb(190, 190, 190) solid;
  font-size: 16px;
  overflow-y: inherit;
}

.table-body:hover {
  background-image: linear-gradient(to right, white, rgb(240, 240, 240), white);
}

.table-body-fixed:hover {
  background-image: linear-gradient(to right, white, rgb(240, 240, 240), white);
}

.activate-icon {
  width: 20px;
  height: auto;
  background-color: none;
  outline: none;
}

.search {
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;
}

.center-text {
  text-align: center;
  margin-bottom: 3.5px;
}

.left-text {
  margin-bottom: 3.5px;
}

/* UNIFICACÃO */
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-blend-mode: darken;
}

.page-body-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  width: 100%;
  margin-top: 100px;
}

.page-top-menu-area {
  display: flex;
  width: 100%;
  height: 40px;
  flex-flow: row nowrap;
  margin: 10px 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  align-content: center;
}

.page-table-area {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: calc(100vh - 160px);
}

.page-dashboard-area {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-rows: auto;
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  padding: 0 1%;
  background: white;
}

.table-component-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 10px);
  overflow: auto;
  margin: 0 20px 0 12px;
}

.table-component-table-area {
  display: flexbox;
  align-items: center;
  background-color: #fff;
  border: 1px #9fb6dd solid;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 40px);
  overflow: auto;
}

.table-component-table-body {
  font-size: 0.8rem;
  width: 100%;
  border-radius: 8px;
  border-collapse: collapse;
  border-left: 1px #9fb6dd solid;
}

.table-component-table-th {
  position: sticky;
  top: 0;
  height: 30px;
  max-height: 30px;
  justify-content: space-around;
  background: #9fb6dd;
  color: #171f48;
  z-index: 1;
  border-bottom: 1px #9fb6dd solid;
  border-right: 1px #c5d4ec solid;
  font-size: 0.875rem;
}

.table-component-table-th-first-column {
  position: sticky;
  left: 0;
  z-index: 3;
}

.table-component-table-th-last-column {
  position: sticky;
  right: 0;
  z-index: 3;
}

.table-component-table-header {
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 10px;
}

.table-component-table-filter-area {
  width: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
}

.table-component-sort-button {
  cursor: pointer;
}

.table-component-rows-area {
  width: 100%;
  height: 100%;
  max-height: calc(100% - 40px);
}

.table-component-no-results {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px;
  justify-content: center;
  margin-top: 50px;
  position: fixed;
}

.table-rows-component-tr {
  height: 40px;
  max-height: 40px;
  border: 1px #ebf3fe solid;
  font-size: 0.875rem;
}

.table-rows-component-tr:hover {
  background-color: #ebf3fe;
}

.table-rows-component-td {
  padding-left: 10px;
  border-right: 1px #ebf3fe solid;
}

.table-rows-component-first-column {
  position: sticky;
  left: 0;
  background-color: #FFF;
}

.table-rows-component-last-column {
  position: sticky;
  right: 0;
  background-color: #FFF;
  border-left: 1px #ebf3fe solid;
}

.table-rows-component-buttons-cell {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-color: #FFF;
}

.table-buttons {
  display: flex;
  justify-content: flex-end;
  width: 30px;
  cursor: pointer;
}

.page-form-area {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  height: calc(100vh - 160px);
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 10px);
  margin: 0 20px 0 12px;
  overflow: auto;
}

.form-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  row-gap: 4px;
}

.form-header-row {
  display: flex;
  width: 100%;
  height: 100%;
  column-gap: 8px;
  flex-wrap: wrap;
}

.form-label {
  display: flex;
  font-size: 12px;
  margin-bottom: 0;
  color: #171f48;
  font-weight: 700;
}

.input-label {
  display: inline;
  margin: 0 auto 0 0;
}

.form-data-container {
  display: flex;
  column-gap: 4px;
  overflow: auto;
  width: 100%;
}

.form-data-container-data {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
}

.form-data-container-card-workflow-1 {
  display: flex;
  flex-direction: column;
  height: 267px;
  padding: 8px;
  border: 2px solid #9fb6dd;
  border-radius: 20px;
  box-shadow: 1.95px 1.95px 6px rgba(0, 0, 0, 0.15);
}

.form-data-container-card-workflow-2 {
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 8px;
  border: 2px solid #9fb6dd;
  border-radius: 20px;
  box-shadow: 1.95px 1.95px 6px rgba(0, 0, 0, 0.15);
}

.form-data-container-card-workflow-5 {
  display: flex;
  flex-direction: column;
  height: 346px;
  padding: 8px;
  border: 2px solid #9fb6dd;
  border-radius: 20px;
  box-shadow: 1.95px 1.95px 6px rgba(0, 0, 0, 0.15);
}

.form-data-container-card-title {
  display: flex;
  column-gap: 8px;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-data-container-card-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.38);
  color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 13px;
}

.form-data-container-card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0;
  column-gap: 6px;
}

.form-data-container-logs {
  display: flex;
  flex-direction: column;
  min-width: 430px;
  max-width: 430px;
  height: 100%;
}

.form-data-container-card-horizontal-separator {
  width: 99%;
  align-self: center;
  margin: 8px 0;
  border: 1px solid #9fb6dd;
}

.horizontal-separator {
  width: 100%;
  align-self: center;
  margin: 16px 0;
  border: 2px solid #9fb6dd;
  border-radius: 2px;
}

.vertical-separator {
  height: 100%;
  align-self: center;
  margin: 0 16px;
  border: 2px solid #9fb6dd;
  border-radius: 2px;
}

.activate-button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.link:hover {
  cursor: pointer;
}

.edit-icon {
  margin-left: 4px;
}

.edit-icon:hover {
  cursor: pointer;
}

.date-input {
  height: 35px;
  width: 130px;
  padding: 8px;
  font-size: 13px;
  color: #373a3c;
  border: 1px solid #818a91;
  border-radius: 3px;
  text-align: center;
}

.monetary-value-input {
  height: 35px;
  width: 166px;
  padding: 8px 8px 8px 26px;
  font-size: 13px;
  color: #373a3c;
  border: 1px solid #818a91;
  border-radius: 3px;
  text-align: right;
}

.installments-input {
  height: 35px;
  width: 166px;
  padding: 8px 8px 8px 26px;
  font-size: 13px;
  color: #373a3c;
  border: 1px solid #818a91;
  border-radius: 3px;
  text-align: center;
}

.case-number-input {
  height: 35px;
  width: 180px;
  padding: 8px;
  font-size: 13px;
  color: #373a3c;
  border: 1px solid #818a91;
  border-radius: 3px;
  text-align: center;
}

.distribution-date-input {
  width: 144px;
}

.docs-label-column {
  width: 110px;
}

.kit-label-column {
  width: 80px;
}

.distribution-label-column {
  width: 80px;
}

.negative-result-label-column {
  width: 120px;
}

.text-area-input {
  display: flex;
  width: 100%;
  font-size: 13px;
  padding: 8px;
  border: 1px solid #818a91;
  border-radius: 3px;
  resize: none;
}

.evaluation-new-comment {
  display: flex;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  border: 1px #818a91 solid;
  border-radius: 3px;
  align-items: center;
}

.log-input {
  display: flex;
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 13px;
}

.evaluations-log-field {
  display: flex;
  height: 100%;
  border: 1px solid #818a91;
  border-radius: 3px;
  overflow-y: scroll;
}

.log-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  padding: 2px;
  font-size: 12px;
  background-color: rgb(240, 240, 240);
}