.evaluation-form-modal {
  display: flex;
}

.gray-button-cancel:hover {
  color: #FFF;
  background: rgb(179, 179, 179);
  cursor: pointer;
}

.title-modal {
  width: 100%;
}

.close-button {
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
}