.user-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email-input {
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 0 15px 0;
}

.dialog-actions {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
}