.blue-button {
  height: 35px;
  width: 100px;
  border-radius: 5px;
  background: #171f48;
  border: none;
  color: #FFF;
  font-weight: bold;
}

.blue-button:hover {
  color: #EFC337;
  cursor: pointer;
}