.dashboard-card {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    font-size: 0.9vw;
    padding: 1% 0;
    white-space: normal;
    padding: 0 10px;
}