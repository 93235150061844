.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(11, 11, 11, 0.5);
  background-image: url("../../images/background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-button {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
}

.card {
  width: 20%;
  min-width: 392px;
  height: 480px;
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  margin: 20px auto;
  background-color: #f3e9c9;
  border: none;
  border-radius: 10px;
}

.card-content {
  height: 50%;
  background-color: #171f48;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
}

.login-row-email {
  margin: 10px 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
}

.login-row-password {
  margin: 10px 0;
  display: flex;
  width: 100%;
}

.login-password-visibility {
  margin-left: -30px;
  align-self: center;
}

.login-title {
  margin-top: 40px;
  color: #FFF;
  font-size: 20px;
}

.login-input {
  display: flex;
}

.login-label {
  margin-bottom: 0;
  font-weight: bold;
  color: #171F48;
  font-size: 14px;
}

.login-email {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: small;
  font-size: 13px;
  margin-left: 5px;
  font-weight: 400;
}

.recover-password {
  display: flex;
  font-size: 10px;
  color: #171F48;
  cursor: pointer;
}

.recover-password:hover {
  color: #EFC337;
}

.login-content {
  display: flex;
  flex-flow: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.dialog-title {
  display: flex;
  font-size: 20px;
  background-color: #171f48;
  color: #fff;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 70px;
  border-bottom: 2px #171f48 solid;
}

.dialog-subtitle {
  color: #171f48;
  font-size: 13px;
  margin-bottom: 20px;
}

.recover-button {
  width: 50px;
}