.row1 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.client-row1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.client-row1-col1 {
  width: 60%;
  display: flex;
  flex-flow: column;
  margin: 0 10px;
  padding-right: 10px;
}

.client-row1-col2 {
  width: 40%;
  display: flex;
  flex-flow: row nowrap;
  padding-right: 10px;
}

.client-email {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0 0 0;
}

.client-tax-id {
  display: flex;
  width: 65%;
  flex-direction: column;
  margin: 0 5px 0 0;
  min-width: 138px;
}

.client-date {
  display: flex;
  width: 45%;
  flex-direction: column;
}