.search-filter {
    display: flex;
    width: 100%;
    height: 0px;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    position: absolute;
    right: 140px;
    bottom: 85px;
}

.search-input {
    display: flex;
    height: 20px;
    width: 400px;
    min-width: 300px;
    margin-bottom: -10px;
}

.search-input:focus {
    background-color: rgba(252, 252, 252, 0.8);
}