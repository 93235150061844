.row1 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.partner-row1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.partner-row1-col1 {
  width: 60%;
  display: flex;
  flex-flow: column;
  margin: 0 0px 0 10px;
  padding-right: 20px;
}

.partner-row1-col2 {
  width: 40%;
  display: flex;
  flex-flow: column;
}

.partner-row1-col2-block1 {
  display: flex;
  width: 100%;
}

.partner-row1-col2-block2 {
  display: flex;
  width: 100%;
  gap: 5px;
  margin: 10px 0 0 0;
}

.partner-email {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0 0 0;
}

.partner-location {
  display: flex;
  width: 100%;
  gap: 5px;
  margin: 10px 0 0 0;
}

.partner-city {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.partner-state {
  width: 10%;
  flex-direction: column;
}

.partner-tax-id {
  display: flex;
  width: 45%;
  flex-direction: column;
  min-width: 138px;
}

.partner-tax-id-type {
  display: flex;
  width: 30%;
  flex-direction: column;
  margin: 0 5px 0 0;
}

.partner-code {
  display: flex;
  width: 25%;
  flex-direction: column;
  margin: 0 5px 0 0;
}

.partner-phone {
  display: flex;
  width: 35%;
  margin: 0 5px 0 0;
  flex-direction: column;
  min-width: 132px;
}

.partner-phone-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.partner-phone-details {
  display: flex;
  width: 65%;
  flex-direction: column;
  min-width: 46px;
}

.partner-label {
  display: flex;
  font-size: 12px;
  margin-bottom: 0;
  color: #171f48;
  font-weight: 700;
}