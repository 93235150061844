.display-header {
  display: flex;
  width: 100%;
  height: 100px;
  position: fixed;
  flex-flow: row nowrap;
  top: 0;
  background-color: #171f48;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 15px;
  align-content: center;
  z-index: 5;
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.3);
}

.header-logo-display {
  display: flex;
  width: 30%;
  min-width: 150px;
}

.header-logo {
  width: 100%;
  max-width: 200px;
  height: auto;
  display: flex;
  align-items: center;
}

.header-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 25px;
  justify-content: center;
}

.header-secondary-title {
  font-size: 22px;
  font-weight: normal;
}

.header-user-name {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  color: white;
}