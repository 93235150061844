.row3 {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 20px;
}

.client-row3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.client-row3-col1 {
  width: 60%;
  display: flex;
  flex-flow: column;
  margin: 0 10px;
}

.client-row3-col2 {
  width: 40%;
  display: flex;
  flex-flow: column;
  margin: 0 10px;
}

.client-benefit-and-origin {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input-benefit-and-origin-group {
  width: 100%;
}

.benefit-and-origin-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.client-retirement-benefit {
  width: 100%;
}

.client-retirement-benefit-date {
  width: 100%;
  margin: 0 5px;
}

.client-origin {
  width: 100%;
}

.client-partners {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input-partners-group {
  width: 100%;
  margin: 10px 10px 0 0;
}

.partners-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.client-logs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.client-log-field {
  height: 120px;
  width: 100%;
  display: flex;
  border: 1px solid #818a91;
  border-radius: 3px;
  overflow-y: scroll;
}

.client-lis-pendens {
  width: 60%;
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
}

.client-admission {
  width: 20%;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column
}

.client-registration {
  width: 20%;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column
}

.client-admission-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.card-log {
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: column;
  border-bottom: 1px gray solid;
}

.log-input {
  display: flex;
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 13px;
}

.log-row {
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  padding: 2px;
  font-size: 12px;
  background-color: rgb(240, 240, 240);
}